@import 'src/styles/responsive';

:root {
    /* device sizes */
    --tablet-w: 1000px;
    --mobile-w: 600px;

    /* header */
    --header-h: clamp(60px, calc(60px + (28 + 28 * 0.7) * ((100vw - 320px) / 1440)), 88px);
    --header-z: 10;

    /* main colors */ 
    --color-white: #fff;
    --color-orange: #ED692A;
    --color-orange-dark: #C6511A;
    --color-blue-dark: #12123B;
    --color-grey-01: #676785;

    --color-light-green: #C6E8CA;

    /* text colors */
    --text-purple: #5E5EB8;
    --text-purple-light: #C3C3FF;
    --text-green: #A5DBAB;

    /* bg colors */
    --bg-dark: #0C0C2C;
    --bg-dark-medium: #281D68;
    --bg-dark-light: #6159BC;

    @media screen and (min-width: 769px) {
        --header-h: clamp(60px, calc(60px + 28 * (100vw / 1440)), 88px);
    }

}

html * {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    // border: 1px solid red;
}

html,
body {
    width: 100%;
    // height: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.no-touch-scroll {
    touch-action: none;
    -ms-touch-action: none;
    overflow: hidden;
}

.scroll-mt {
    // header 60px + 15 = 75px
    // header 88px + 20 = 108px
    scroll-margin-top: responsive(75, 108, 'mobile-adaptive');

    @media screen and (min-width: 769px) {
        scroll-margin-top: responsive(75, 108);
    }
}

.flex--c-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-v--c-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex--sb-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-v--sb-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}