@import 'src/styles/responsive';

.header { 
    position: fixed;
    inset: 0 0 auto 0;
    z-index: var(--header-z);

    background-color: rgba(255, 255, 255, 0.02);

    border-radius: 0 0 responsive(20, 40, 'mobile-adaptive') responsive(20, 40, 'mobile-adaptive');
    backdrop-filter: blur(5px);
    transition: background-color 0.2s ease-in-out;

    &--active {
        background-color: #fff;
        box-shadow: 0 4px 40px rgba(0,0,0,.05);
    }

    &__container {
        padding: 0 responsive(15, 60, 'mobile-adaptive');
        height: var(--header-h);

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    &__main-links,
    &__phone {
        display: none;
    }

    &__burger-btn {
        display: block;
        cursor: pointer;

        & path {
            stroke: var(--color-white);
            transition: stroke 0.3s ease-in-out;
        }

        & rect {
            fill: var(--color-white);
            transition: fill 0.3s ease-in-out;
        }

        @media (hover: hover) {
            &:hover {
                & path {
                    stroke: var(--color-orange);
                }

                & rect {
                    fill: var(--color-orange);
                }
            }
        }
    }

    & .mobile-menu {
        padding-top: calc(var(--header-h) + 30px);
        padding-bottom: 40px;

        min-height: 100dvh;
    
        position: fixed;
        inset: 0;
        z-index: -1;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        background-color: #fff;

        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;

        &--active {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
        }
    
        &__main-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }
    
        &__btn {
            margin-top: 60px;
        }
    
        &__footer-links {
            margin-top: auto;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
    
            & a {
                color: #568977;
            }
        }
    }

    @media screen and (min-width: 769px) {
        border-radius: 0 0 responsive(20, 40) responsive(20, 40);

        &__container {
            padding: 0 responsive(15, 60);
        }
    }

    @media screen and (min-width: 1201px) {
        &--active {
            & .link {
                color: #568977;

                @media (hover: hover) {
                    &:hover {
                        color: #104431;
                    }
                }
            }
        }

        &__main-links {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: responsive(0, 40);
        }

        &__phone {
            display: block;
        }

        &__burger-btn,
        & .mobile-menu {
            display: none;
        }
    }
}

