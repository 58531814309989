.social-link {
    border-radius: 50%;

    & circle {
        // fill: var(--bg-dark-medium);
        fill: #1D694E;
        transition: fill 0.3s ease-in-out;

        cursor: pointer;
    }

    @media (hover: hover) {
        &:hover circle {
            // fill: var(--color-orange);
            fill: #568977;
        }
    }
}