// [class*='map'] {
//     border-radius: 40px;
// }

// [class*='ground-pane'] {
//     filter: grayscale(1) sepia(5%) hue-rotate(205deg) saturate(1200%);
// }

#map {
    position: relative;

    & .custom-y-map-placeholder {
        position: absolute;
        inset: 0;
        z-index: 1;

        background-image: url('./images/placeholder.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        pointer-events: none;
    }    
}
