@import 'src/styles/responsive';

.app {
    min-height: 100vh;
    background: var(--color-light-green);

    overflow-x: hidden;
}

/* layout styles */

.container {
    max-width: 1440px;
    width: 100%;

    margin: 0 auto;
    padding: 0 responsive(10, 60, 'mobile-adaptive');
    
    @media screen and (min-width: 769px) {
        padding: 0 responsive(10, 60);
    }
}

.section {
    margin-top: responsive(60, 100, 'mobile-adaptive');

    display: flex;
    flex-direction: column;
    align-items: center;

    &__sub-title {
        width: fit-content;
        padding: responsive(14, 17, 'mobile-adaptive') 20px;
        /* height: 44px - 50px */

        font-family: 'Inter';
        font-size: 16px;
        font-weight: 600;
        line-height: 1;

        color: #568977;

        border-radius: 30px;
        background: var(--text-green);
    }

    &__title {
        margin-top: responsive(20, 30, 'mobile-adaptive');
    }

    &__body {
        margin-top: responsive(40, 60, 'mobile-adaptive');
        max-width: 100%;
    }

    @media screen and (min-width: 769px) {
        & {
            margin-top: responsive(60, 100);
        }

        &__sub-title {
            padding: responsive(14, 17) 20px;
            /* height: 44px - 50px */
        }

        &__title {
            margin-top: responsive(20, 30);
        }

        &__body {
            margin-top: responsive(40, 60);
        }
    }
}

/* common styles */

.title {
    font-family: 'Futuris';
    font-weight: 700;
    font-size: responsive(36, 60, 'mobile-adaptive');
    line-height: 1;

    text-align: center;

    color: var(--color-white);

    /* different sizes */

    &--md {
        color: #104431;
        font-size: responsive(24, 60, 'mobile-adaptive');
        line-height: responsive(32, 60, 'mobile-adaptive');
    }

    &--sm {
        font-family: 'Inter';
        font-size: 40px;
        line-height: 50px;
        font-size: responsive(20, 40, 'mobile-adaptive');
        line-height: responsive(28, 50, 'mobile-adaptive');
    }

    /* multi-color with span */

    & span {
        color: var(--text-green);
    }

    @media screen and (min-width: 769px) {
        & {
            font-size: responsive(36, 60);
        }

        &--md {
            font-size: responsive(24, 60);
            line-height: responsive(32, 60);
        }

        &--sm {
            font-size: responsive(20, 40);
            line-height: responsive(28, 50);
        }
    }
}

.link {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: responsive(20, 24, 'mobile-adaptive');

    color: var(--color-white);
    
    transition: color 0.3s ease-in-out;

    &--500 { font-weight: 500; }
    &--600 { font-weight: 600; }
    &--700 { font-weight: 700; }

    &--underlined {
        text-decoration: underline;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--color-orange);
        }
    }

    @media screen and (min-width: 769px) {
        line-height: responsive(20, 24);
    }

    &--dark {
        color: #104431;

        @media (hover: hover) {
            &:hover {
                color: #104431;
            }
        }
    }
}

.tag {
    width: fit-content;
    padding: 21px 20px;
    padding: responsive(13, 21, 'mobile-adaptive') 20px;
    /* height: 44px - 60px */

    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    text-align: center;

    color: var(--color-white);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(15px);

    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.30);

    &--sm {
        padding: responsive(13, 16, 'mobile-adaptive') 20px;
        /* height: 44px - 50px */
    }

    @media screen and (min-width: 769px) {
        & {
            padding: responsive(13, 21) 20px;
            /* height: 44px - 60px */

            font-weight: 700;
        }

        &--sm {
            padding: responsive(13, 16) 20px;
        }
    }
}

.btn {
    padding: responsive(17, 20, 'mobile-adaptive') 30px;
    /* height: 50px - 60px */

    font-family: 'Inter';
    font-weight: 700;
    font-size: responsive(16, 20, 'mobile-adaptive');
    line-height: 1;
    text-align: center;

    color: var(--color-white);
    background-color: var(--color-orange);

    border-radius: 40px;
    cursor: pointer;

    transition:
        background-color 0.3s ease-in-out,
        opacity 0.3s ease-in-out;

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }

    &--small {
        font-size: responsive(14, 16, 'mobile-adaptive');
    }

    @media (hover: hover) {
        &:hover {
            background-color: var(--color-orange-dark);
        }
    }

    @media screen and (min-width: 769px) {
        padding: responsive(17, 20) 30px;
        /* height: 50px - 60px */

        font-size: responsive(16, 20);

        &--small {
            font-size: responsive(14, 16);
        }
    }
}

.input,
.textarea {
    width: 100%;
    padding: responsive(15, 20, 'mobile-adaptive') 20px;
    /* height: 50px - 60px */

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    border: 2px solid transparent;
    border-radius: 14px;
    
    color: #104431;
    background-color: #A5DBAB;

    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out
        opacity 0.2s ease-in-out;

    &:placeholder-shown {
        color: #568977;
        background-color: #C6E8CA;
    }

    @media (hover: hover) {
        &:hover {
            border-color: #A5DBAB;
        }
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: #104431;;
        box-shadow: 0 0 0px 40rem #A5DBAB inset;
        border: 2px solid transparent;
        

        transition:
            background-color 9999s ease-in-out 0s,
            box-shadow 0.3s ease-in-out,
            color 0.3s ease-in-out,
            border-color 0.3s ease-in-out;

        @media (hover: hover) {
            &:hover {
                border-color: #A5DBAB;
            }
        }
    }

    &[error=true] {
        border-color: #D96060;
    }

    &[disabled=true] {
        opacity: 0.6;
        pointer-events: none;
    }

    @media screen and (min-width: 769px) {
        & {
            padding: responsive(15, 20) 20px;
        }
    }
}

.textarea {
    resize: none;
    height: 84px;
}

.label {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    color: #568977;

    &--required {
        &::after {
            padding-left: 5px;
            content: '*';
        }
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    gap: responsive(5, 10, 'mobile-adaptive');

    @media screen and (min-width: 769px) {
        gap: responsive(5, 10);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    z-index: 50;

    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);

    opacity: 1;
    animation: fadeInWithDisplayFlex 0.15s ease-in forwards;
    overflow: hidden;
    
    &__v-scroll {
        width: 100%;
        padding: 35px 20px;
        overflow-y: scroll;

        & > * {
            margin: 0 auto;
        }
    }

    &--hide {
        display: none;
        opacity: 0;
    }
}

.loader {
    width: responsive(25, 50, 'mobile-adaptive');
    height: responsive(25, 50, 'mobile-adaptive');
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @media screen and (min-width: 769px) {
        width: responsive(25, 50);
        height: responsive(25, 50);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}