@import 'src/styles/responsive';

.hero {
    position: relative;
    overflow: hidden;

    height: 610px;
    border-radius: 0px 0px 40px 40px;

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__overlay {
        height: 100%;
        width: 100%;

        /* rgba фильтр для заднего фона */
        background-color: rgba(0, 0, 0, 0.32);
    }

    &__title {
        margin-top: calc(var(--header-h) + responsive(40, 110, 'mobile-adaptive'));

        z-index: 1;
    }

    &__tags {
        margin-top: 20px;
        width: fit-content;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        z-index: 1;
    }

    &__btn {
        margin-top: 80px;
        width: responsive(140, 160, 'mobile-adaptive');

        z-index: 1;
    }

    &::before {
        position: absolute;
        content: '';

        max-width: 320px;
        width: 100%;

        top: -50%;
        bottom: -50%;
        left: 50%;
        transform: translateX(-50%);

        z-index: 1;
    
        background: url('./img/bg-icons-mobile.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    @media screen and (min-width: 600px) {
        &::before {
            max-width: 1478.25px;
            width: responsive(1000, 1478.25);
            
            top: -48%;
            bottom: -52%;
            left: 50%;
            transform: translateX(-50%);
    
            background-image: url('./img/bg-icons.png');
        }
    }

    @media screen and (min-width: 769px) {
        &__title {
            margin-top: calc(var(--header-h) + responsive(40, 110));

            & br {
                display: none;
            }
        }

        &__tags {
            margin-top: 30px;
            flex-direction: row;
        }

        &__btn {
            margin-top: 100px;
            width: responsive(140, 160);
        }
    }
}