@import 'src/styles/responsive';

.select {
    --input-background-color: #fff;
    --padding-right: calc(20px + 24px + 14px);
    
    position: relative;
    z-index: 0;

    border-radius: 14px;
    transition:
        opacity 200ms ease-in-out,
        z-index 0ms 200ms;

    &--active {
        z-index: 25;
        transition:
            z-index 0ms;
    }

    &--disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    & .select-input {
        position: relative;
        z-index: 10;

        padding-right: var(--padding-right);
        width: 100%;

        // background-color: var(--input-background-color);
        cursor: pointer;
    }

    & .select-arrow {
        position: absolute;
        right: 20px;
        top: calc((responsive(53, 63, 'mobile-adaptive') - 24px) / 2);
        z-index: 10;

        line-height: 0;
        pointer-events: none;
        transition: transform 200ms ease-in-out;
    
        &--active {
            transform: rotate(180deg);
        }
    }

    & .select-options {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;

        padding: 20px;
        padding-top: calc(responsive(53, 63, 'mobile-adaptive') + 20px);

        background-color: #fff;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0);
        border-radius: 14px;

        transform: scaleY(0);
        transform-origin: center calc(responsive(53, 63, 'mobile-adaptive') / 2);
        transition:
            box-shadow 200ms ease-in-out,
            transform 200ms ease-in-out;

        &--active {
            transform: scaleY(1);
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
        }

        &_container {
            overflow-y: auto;
            overscroll-behavior: none;
            max-height: 118px;

            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &::-webkit-scrollbar {
                width: 4px;
                height: 0px;
            }
            
            &::-webkit-scrollbar-track {
                background: #DBDBEE;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: var(--grey);
                border-radius: 5px;
            }
        }
    }

    & .select-option {
        padding-right: 10px;

        font-family: 'Inter';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #568977;

        cursor: pointer;
        transition:
            color 200ms ease-in-out;

        @media (hover: hover) {
            &:hover {
                color: #104431;
            }
        }

        &--active {
            color: #104431;

            &::before {
                content: '->';
                padding-right: 5px;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.6;
        }
    }

    &_add-button {
        margin-top: 20px;
    }

    @media screen and (min-width: 769px) {
        & .select-arrow {
            top: calc((responsive(53, 63) - 24px) / 2);
        }

        & .select-options {
            padding-top: calc(responsive(53, 63) + 20px);
            transform-origin: center calc(responsive(53, 63) / 2);
        }
    }
}