@import 'src/styles/responsive';

.program {
    position: relative;

    overflow-x: visible;

    &__orange-gradient {
        display: none;
    }

    &__body {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: responsive(60, 100, 'mobile-adaptive');

        z-index: 1;
    }

    @media screen and (min-width: 769px) {
        &__body {
            gap: responsive(60, 100);
        }
    }

    @media screen and (min-width: 1001px) {
        &__orange-gradient {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            
            transform: translate(-50%, 18%) scale(1.6);
            pointer-events: none;
            user-select: none;

            animation: fadeIn 0.3s ease-in forwards;
        }
    }
    
}

/* БЛОК - СЛАЙДЕР С ИВЕНТАМИ МЕРОПРИЯТИЯ */

.program-events {
    position: relative;
    max-width: 100% !important;
    width: 100%;

    display: grid;
    row-gap: 10px;

    // overflow-x: hidden;

    &__col {
        display: grid;
        row-gap: 10px;
    }
    
    &__item {
        padding: responsive(20, 40, 'mobile-adaptive');
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        
        border-radius: responsive(20, 40, 'mobile-adaptive');
        background-color: #A5DBAB;

        & h4 {
            text-align: center;
            font-family: 'Futuris';
            font-weight: 700;
            font-size: responsive(16, 20, 'mobile-adaptive');
            line-height: responsive(20, 24, 'mobile-adaptive');

            color: #104431;
        }

        & p {
            margin-top: 6px;

            text-align: center;
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            color: #568977;
        }

        &--bg {
            position: relative;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            overflow: hidden;

            & h4, & p {
                color: #fff;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 0;
                inset: 0;
                background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
            }
        }

        &--1st { 
            height: 300px;
            justify-content: flex-end;
            background-image: url('./img/program-events-01.jpg');
        }

        &--2nd {
            height: 180px;
            & img { width: 100px; }
        }

        &--3rd {
            height: 140px;
            & img { width: 42px; }
        }

        &--4th { 
            height: 180px;
            background-color: #1D694E;
            & img { width: 100px; }
            & h4 { color: #fff; }
        }

        &--5th { 
            height: 140px;
            & img { width: 46px; }
        }

        &--6th {
            height: 180px;
            & img { width: 100px; }
        }

        &--7th { 
            height: 300px;
            justify-content: flex-end;
            background-image: url('./img/program-events-07.jpg');
        }

    }

    @media screen and (min-width: 769px) {
        &__item {
            padding: responsive(20, 40);
            border-radius: responsive(20, 40);
            
            & h4 {
                font-size: responsive(16, 20);
                line-height: responsive(20, 24);
            }
        }
    }

    @media screen and (min-width: 1101px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;

        &__item {
            &--1st { height: 464px; }

            &--2nd { 
                height: 298px;
                & img { width: responsive(100, 174); }
            }
    
            &--3rd { 
                height: 234px; 
                & img { width: responsive(42, 77); }
            }
            
            &--4th { 
                height: 284px;
                & img { width: responsive(100, 160); }
            }
            
            &--5th {
                height: 234px;
                & img { width: responsive(46, 85); }
            }
    
            &--6th { 
                height: 298px;
                & img { width: responsive(100, 174); }
            }
    
            &--7th { height: 463px; }
        }
    }
}

/* БЛОК С РАСПИСАНИЕМ ИВЕНТОВ */

.schedule {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;

    &__image {
        display: none;
    }

    @media screen and (min-width: 769px) {
        &__image {
            height: responsive(195, 390);
            border-radius: responsive(20, 40);

            & h3 {
                left: responsive(20, 60);
                top: responsive(20, 60);
            }
        }
    }

    @media screen and (min-width: 1101px) {
        grid-template-columns: 1fr 2fr;
        column-gap: 10px;

        &__title {
            display: none;
        }

        &__image {
            display: block;
            position: relative;

            // height: auto;
            
            // под максимально возможную высоту контента
            // min-height: 640px;
            min-height: 696px;
            
            line-height: 0;
            overflow: hidden;
            
            border-radius: responsive(20, 40);
            z-index: 1;

            & h3 {
                position: absolute;

                left: responsive(20, 60);
                top: responsive(20, 60);
            }

            & img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
    }
}

/* ДЕКСТОПНОЕ РАСПИСАНИЕ */

.schedule-desktop {
    display: none;

    @media screen and (min-width: 1101px) {
        & {
            height: 100%;
            padding: responsive(20, 60);

            display: flex;
            flex-direction: column;
            gap: 40px;

            border-radius: 40px;
            background-color: #fff;

            &__controls {
                display: flex;
                gap: 10px;

                &-btn {
                    padding: 14.75px 30px; /* height: 52px */
            
                    font-family: 'Inter';
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1;
                    color: #568977;
            
                    border-radius: 40px;
                    border: 2px solid rgb(86, 137, 119, 0.3);
            
                    cursor: pointer;
            
                    transition: 
                        border-color 0.3s ease-in-out,
                        color 0.3s ease-in-out;
                    
                    @media (hover: hover) {
                        &:hover {
                            border-color: #568977;
                        }
                    }
            
                    &[data-active=true] {
                        color: #1D694E;
                        border-color: #1D694E;
                    }
                }
            }

            &__event-list {
                column-count: 2;
                column-gap: 20px;

                animation: fadeIn 0.8s ease-in forwards;
            }

            &__event {
                display: flex;
                align-items: center;
                gap: 28px;

                &:not(:first-child) {
                    margin-top: 14px;
                }

                &-time {
                    align-self: flex-start;
                    
                    font-family: 'Futuris';
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px; /* 120% */

                    color: #104431;
                }

                &-title {
                    font-family: 'Inter';
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px; /* 150% */

                    color: #568977;
                }
            }

            &__link {
                margin-top: auto;

                font-family: 'Inter';
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                text-decoration: underline;
                
                color: #568977;
            }
        }
    }
}

/* МОБИЛЬНОЕ РАСПИСАНИЕ - ВЫПАДАЮЩИЕ СПИСКИ */

.schedule-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    &__item {
        display: flex;
        flex-direction: column;
        gap: 20px;
    
        border-radius: 30px;
        background-color: #fff;
    
        &--active {
            box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
        }

        &-btn {
            height: 60px;
            padding: 0 10px 0 20px;
        
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
        
            border-radius: 40px;
            background-color: #fff;
        
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            & p {
                font-family: 'Inter';
                font-size: 16px;
                font-weight: 700;
                line-height: 24px; /* 150% */
                color: #104431;
            }
        
            & div {
                width: 40px;
                height: 40px;
            
                display: flex;
                justify-content: center;
                align-items: center;
            
                border-radius: 50%;
                border: 2px solid #C6E8CA;
            
                transition: transform 0.3s ease-in-out;
            }

            & svg path {
                stroke: #104431;
            }

            &--active {
                background-color: #1D694E;

                & p {
                    color: #fff;
                }
            
                & div {
                    border-color: #568977;
                    transform: rotate(180deg);
                }

                & svg path {
                    stroke: #fff;
                }
            }
        }

        &-container {
            padding: 0 20px 30px 20px;
        
            display: flex;
            flex-direction: column;
            gap: 8px;
        
            opacity: 0;
            max-height: 0px;
            animation: slideDown 0.35s ease-in-out forwards;

            &-item {
                display: flex;
                gap: 14px;
            
                & p:first-child {
                    font-family: 'Futuris';
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px; /* 120% */
                    color: #104431;
                }
            
                & p:last-child {
                    font-family: 'Inter';
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px; /* 150% */
                    color: #568977;
                }
            }
        }
    }

    &__link {
        margin-top: auto;

        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-decoration: underline;
        text-align: center;
        
        color: #568977;
    }

    @media screen and (min-width: 1101px) {
        & {
            display: none;
        }
    }
}