.check-box {
    display: flex;
    gap: 10px;

    & svg {
        cursor: pointer;
        min-width: 20px;
        min-height: 20px;

        & path {
            transition: stroke-dashoffset 0.3s ease-in-out;
        }
    }

    label {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        color: #568977;

        & a {
            text-decoration-line: underline;
        }
    }
}