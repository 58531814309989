@import 'src/styles/responsive';

.footer {
    padding: responsive(20, 30, 'mobile-adaptive') 0;

    background-color: #104431;

    &__container {
        display: grid;
        row-gap: 30px;
    }

    &__divider {
        height: 1px;
        border-radius: 5px;
        background-color: rgba(#568977, 0.2);
    }

    &__row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        & #rights {
            order: 2;
        }
    }

    &__social-links {
        padding-left: responsive(-40, 40, 'mobile-adaptive');

        display: flex;
        align-items: center;
        gap: 10px;
    }

    & .contacts-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    
        &__group {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
        }
    
        &__title {
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            
            color: #568977;
        }
    
        &__links {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
    }

    @media screen and (min-width: 421px) {
        & .contacts-menu {
            gap: 20px;
        }
    }

    @media screen and (min-width: 769px) {
        padding: responsive(20, 30) 0;

        &__social-links {
            padding-left: responsive(-40, 40);
        }

        & .contacts-menu {
            gap: responsive(-85, 100);

            &__links {
                gap: responsive(0, 30);
            }
        }
    }

    @media screen and (min-width: 850px) {
        &__row {
            flex-direction: row;
            gap: 0;

            & #rights {
                order: 0;
            }
        }

        & .contacts-menu {
            flex-direction: row;

            &__links {
                & a[href^='tel:'] {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: 1001px) {
        & .contacts-menu {
            flex-direction: row;
            gap: responsive(-180, 100);

            &__links {
                flex-direction: row;

                & .link:nth-child(2) {
                    display: inline-block;
                }
            }
        }
    }
}

