@import 'src/styles/responsive';

.feedback {
    position: relative;

    & .section__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: responsive(30, 60, 'mobile-adaptive');
    }

    & .swiper,
    & .swiper-container {
        overflow: visible;
    }

    &__swiper {
        // margin-top: 30px;

        position: relative;
        // height: responsive(224.5, 278, 'mobile-responsive');
        height: responsive(186, 234, 'mobile-responsive');
        max-width: 100% !important;
        width: 100%;
    
        overflow-x: hidden;
        cursor: grab;

        & .swiper-slide {
            box-sizing: border-box !important;
            width: responsive(250, 400, 'mobile-adaptive') !important;
            height: responsive(186, 234, 'mobile-adaptive') !important;
            overflow: hidden;
            border-radius: responsive(20, 30, 'mobile-adaptive');
            cursor: pointer;
    
            & img {
                object-fit: cover;
                min-height: 100%;
                min-width: 100%;
            }
        }

        & .swiper-pagination {
            bottom: 0 !important;
        }

        & .swiper-pagination-bullet {
            width: responsive(10, 14, 'mobile-adaptive');
            height: responsive(10, 14, 'mobile-adaptive');
    
            background-color: #568977;
            opacity: 0.2;
        }
    
        & .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: #568977;
            opacity: 1;
        }
    }

    &__card {
        width: 100%;
        height: 100%;

        padding: responsive(20, 30, 'mobile-adaptive');

        display: flex;
        flex-direction: column;
        gap: 6px;

        border-radius: 30px;
        background: #fff;

        &-name {
            text-overflow: ellipsis;
            font-family: 'Futuris';
            font-size: responsive(16, 20, 'mobile-adaptive');
            line-height: responsive(20, 24, 'mobile-adaptive');
            font-weight: 700;
            text-align: center;

            color: #104431;
        }

        &-text {
            // -webkit-line-clamp: 7; // значение 7 - если нет заголовков 
            -webkit-line-clamp: 6; // значение 7 - если нет заголовков 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;

            // flex: 1;
            font-family: 'Inter';
            font-size: responsive(14, 16, 'mobile-adaptive');
            line-height: responsive(20, 24, 'mobile-adaptive');
            font-weight: 500;
            text-align: center;

            color: #568977;
        }
    }

    &__btn {
        width: 100%;
    }

    @media screen and (min-width: 450px) {
        &__btn {
            width: fit-content;
        }
    }

    @media screen and (min-width: 769px) {
        & .section__body {
            row-gap: responsive(30, 60);
        }

        &__swiper {
            // height: responsive(224.5, 278);
            height: responsive(186, 234);
        }

        & .swiper-slide {
            width: responsive(250, 400) !important;
            height: responsive(186, 234) !important;

            border-radius: responsive(20, 30);
        }

        & .swiper-pagination-bullet {
            width: responsive(10, 14);
            height: responsive(10, 14);
        }   

        &__card {
            padding: responsive(20, 30);

            &-name {
                font-size: responsive(16, 20);
                line-height: responsive(20, 24);
                text-align: left;
            }

            &-text {
                -webkit-line-clamp: 6;
                font-size: responsive(14, 16);
                line-height: responsive(20, 24);

                text-align: left;
            }
        }
    }

    @media screen and (min-width: 1101px) {
        &__swiper::before {
            position: absolute;

            top: -44%;
            bottom: -56%;
            margin-left: responsive(-700, 211);
            z-index: 1;
            
            width: 1015px;
            content: '';

            pointer-events: none;
        
            background: url('./img/bg-icons.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}