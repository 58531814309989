@import 'src/styles/responsive';

.logo {
    width: responsive(138, 189, 'mobile-adaptive');
    fill: #fff;

    transition: fill 0.3s ease-in-out;

    &--green {
        fill: #104431;
    }

    &--green-white {
        & .logo__alt-fill {
            fill: #089EB0;
        }
    }

    &--green-orange {
        & .logo {
            &__fill {
                fill: var(--color-orange);
                transition: fill 0.2s ease-in-out;
            }

            &__alt-fill {
                fill: #089EB0;
                transition: fill 0.2s ease-in-out;
            }
        }
    }

    @media screen and (min-width: 769px) {
        width: responsive(138, 189);
    }
}