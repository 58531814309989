@import 'src/styles/responsive';

.modal {
    position: relative;
    // max-width: 1340px;

    padding: responsive(15, 40, 'mobile-adaptive');
    padding-top: responsive(40, 49, 'mobile-adaptive');
    padding-bottom: 40px;

    border-radius: 20px;
    background-color: #fff;

    &__close-btn {
        position: absolute;
        top: responsive(15, 20, 'mobile-adaptive');
        right: responsive(15, 20, 'mobile-adaptive');

        cursor: pointer;

        & path {
            stroke: #568977;
            transition: stroke 0.3s ease-in-out;
        }

        @media (hover: hover) {
            &:hover {
                & path {
                    stroke: #104431;
                }
            }
        }
    }

    &__title {
        font-family: 'Futuris';
        font-weight: 700;
        font-size: responsive(30, 42, 'mobile-adaptive');
        line-height: 1;

        color: #104431;
    }

    &__body {
        margin-top: responsive(20, 40, 'mobile-adaptive');
    }

    
    @media screen and (min-width: 769px) {
        padding: responsive(15, 40);
        padding-top: responsive(40, 49);
        padding-bottom: 40px;

        &__title {
            font-size: responsive(30, 42);
        }

        &__close-btn {
            top: responsive(15, 20);
            right: responsive(15, 20);
        }
    
        &__body {
            margin-top: responsive(20, 40);
        }
    }
}