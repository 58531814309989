.burger-btn {
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;

    &__line {
        position: absolute;
        content: '';

        width: 22px;
        height: 2.5px;

        background-color: var(--color-white);
        border-radius: 5px;

        transition:
            background 0.3s ease-in-out,
            transform 0.15s ease-in-out 0s,
            inset 0.11s ease-in-out 0.11s,
            width 0.11s ease-in-out 0.11s;
    }

    &__upper-line {
        width: 22px;
        top: 7px;
        left: 0;
    }

    &__lower-line {
        width: 12px;
        top: 13px;
        left: 10px;
    }

    @media (hover: hover) {
        &:hover {
            & .burger-btn__line {
                background-color: var(--color-orange);
            }
        }
    }

    &--active {
        & .burger-btn__line {
            inset: 10px 3.5px 10px 3.5px;
            width: 16px;
            transition:
                background 0.3s ease-in-out,
                width 0.11s ease-in-out 0s,
                inset 0.11s ease-in-out 0s,
                transform 0.15s ease-in-out 0.11s;
        }

        & .burger-btn__upper-line {
            transform: rotate(45deg);
        }

        & .burger-btn__lower-line {
            transform: rotate(-45deg);
        }
    }

    &--dark {
        & .burger-btn__line {
            background-color: #568977;
        }

        @media (hover: hover) {
            &:hover {
                & .burger-btn__line {
                    background-color: #104431;
                }
            }
        }
    }
}
