@import 'src/styles/responsive';

.about {
    position: relative;

    &__purple-gradient {
        display: none;
    }

    &__container {
        padding: responsive(40, 80, 'mobile-adaptive') 20px;
        width: 100%;

        border-radius: responsive(20, 40, 'mobile-adaptive');
        // background-color: #1B1B56;
        background-color: #1D694E;

        & br {
            display: none;
        }
    }

    @media screen and (min-width: 769px) {
        &__container {
            padding: responsive(40, 80) 20px;
            border-radius: responsive(20, 40);
        }
    }

    @media screen and (min-width: 900px) {
        & br {
            display: inline;
        }
    }

    @media screen and (min-width: 1101px) {
        &__purple-gradient {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
            
            transform: translate(40%, 80%) scale(5.4);
            pointer-events: none;
            user-select: none;

            animation: fadeIn 0.3s ease-in forwards;
        }
    }
}