@import 'src/styles/responsive';

.faq {
    background-color: #fff;

    &__container {
        position: relative;

        & .section {
            padding-top: responsive(60, 100, 'mobile-adaptive');
            padding-bottom: responsive(60, 100, 'mobile-adaptive');
        }
    }

    & .section__body {
        max-width: 876px;
        width: 100%;
    }

    &__list {
        width: 100% !important;
        display: grid;
        row-gap: responsive(10, 20, 'mobile-adaptive');
    }

    &-element {
        display: grid;
        row-gap: 10px;

        overflow: hidden;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            & p {
                font-family: 'Futuris';
                font-size: responsive(16, 20, 'mobile-adaptive');
                font-weight: 700;
                line-height: 24px;
                color: #104431;
            }

            & button {
                min-width: responsive(40, 50, 'mobile-adaptive');
                min-height: responsive(40, 50, 'mobile-adaptive');

                width: responsive(40, 50, 'mobile-adaptive');
                height: responsive(40, 50, 'mobile-adaptive');

                border-radius: 50%;

                display: flex;
                align-items: center;
                justify-content: center;

                border: 2px solid transparent;
                background-color: #C6E8CA;

                cursor: pointer;
                transition: border 0.3s ease-in-out;

                @media (hover: hover) {
                    &:hover {
                        border-color: #1D694E;
                    }
                }
            }
        }

        &__body {
            max-height: 0;
            display: grid;
            row-gap: 20px;
            will-change: max-height;
            transition: max-height 0.35s ease-in-out;

            &--active {
                max-height: 600px;
            }

            & p {
                font-family: 'Inter';
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                
                color: #568977;
            }
        }
    }


    @media screen and (min-width: 769px) {
        &__list {
            row-gap: responsive(10, 20);
        }

        &-element {
            &__header {
                & p {
                    font-size: responsive(16, 20);
                    color: #104431;
                }

                & button {
                    min-width: responsive(40, 50);
                    min-height: responsive(40, 50);

                    width: responsive(40, 50);
                    height: responsive(40, 50);
                }
            }

            &__body {
                font-weight: 500;
            }
        }
    }
}