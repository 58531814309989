@import 'src/styles/responsive';

.custom-swiper {
    &.swiper,
    &.swiper-container {
        overflow: visible;
    }

    &.swiper {
        position: relative;
        height: responsive(242.5, 264, 'mobile-responsive');
        max-width: 100% !important;
        width: 100%;
    
        // overflow-x: hidden;
        cursor: grab;

        & .swiper-slide {
            position: relative;

            box-sizing: border-box !important;
            width: responsive(300, 322, 'mobile-adaptive') !important;
            height: responsive(204, 220, 'mobile-adaptive') !important;
            overflow: hidden;
            border-radius: responsive(20, 30, 'mobile-adaptive');
            cursor: pointer;
    
            & img {
                object-fit: cover;
                min-height: 100%;
                min-width: 100%;
            }

            & .custom-swiper-play-btn {
                line-height: 0;
                border-radius: 50%;

                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                backdrop-filter: blur(5px);
                pointer-events: none;
            }
        }

        & .swiper-pagination {
            bottom: 0 !important;
            line-height: 1;
        }

        & .swiper-pagination-bullet {
            width: responsive(10, 14, 'mobile-adaptive');
            height: responsive(10, 14, 'mobile-adaptive');
    
            background-color: #568977;
            opacity: 0.2;
        }
    
        & .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: #568977;
            opacity: 1;
        }
    }

    @media screen and (min-width: 769px) {
        &.swiper {
            height: responsive(242.5, 264);
        }

        &.swiper-slide {
            width: responsive(300, 322) !important;
            height: responsive(204, 220) !important;

            border-radius: responsive(20, 30);
        }

        &.swiper-pagination-bullet {
            width: responsive(10, 14);
            height: responsive(10, 14);
        }   
    }

    @media screen and (min-width: 1440px) {
        &.swiper,
        &.swiper-container {
            overflow: hidden;
        }
    }
}

.gallery {
    & iframe {
        width: 100%;
        height: 100%;
    }

    &__zoom-btn {
        position: absolute;
        top: calc(100% - responsive(40, 44, 'mobile-adaptive'));
        right: calc(100% - responsive(40, 44, 'mobile-adaptive'));

        width: 36px;
        height: 36px;
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 20px;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.30);

        cursor: pointer;
    }

    &__loader {
        position: absolute;
        inset: 0;
        background-color: rgba(0,0,0,0.2);
        // opacity: 0.3;

        display: flex;
        align-items: center;
        justify-content: center;

        & .loader {
            transform: scale(0.6);
        }

        // animation: skeleton-loading 1s linear infinite alternate;

        // @keyframes skeleton-loading {
        //     0% { opacity: 0; }
        //     100% { opacity: 0.4; }
        // }
    }

    &__image {
        position: relative;
        height: responsive(176, 640, 'mobile-adaptive');
        border-radius: responsive(20, 30, 'mobile-adaptive');
        background-color: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        
        & img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            // object-fit: contain;

            will-change: opacity;
            animation: fadeIn 0.35s ease-in forwards;        
        }
    }

    &__controls {
        position: absolute;
        inset: 50px 0;
        
        padding: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        // pointer-events: none;

        & > button {
            height: 36px;
            width: 36px;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            line-height: 0;

            backdrop-filter: none;
            background: transparent;
            border: none;

            transition:
                background 0.3s ease-in-out;
            
            pointer-events: all;

            & svg {
                pointer-events: none;
                stroke: var(--color-white);

                will-change: stroke;
                transition: stroke 0.3s ease-in-out;
            }

            @media (hover: hover) {
                &:hover {
                    background-color: transparent;

                    & svg {
                        stroke: var(--color-white);
                    }
                }
            }
        }

        & button:first-child svg {
            margin-left: -3.5px;
        }

        & button:last-child svg {
            margin-right: -3.5px;
        }
    }

    @media screen and (min-width: 601px) {
        &__controls {
            padding: 0 responsive(10, 40);

            & button {
                height: 50px;
                width: 50px;

                border: 2px solid rgba(255, 255, 255, 0.6);
                background-color: rgba(255, 255, 255, 0.1);

                @media (hover: hover) {
                    &:hover {
                        background-color: var(--color-white);

                        & svg {
                            stroke: var(--bg-dark-light);
                        }
                    }
                }
            }
        }

        &__zoom-btn {
            display: none;
        }
    }
    
    @media screen and (min-width: 769px) {
        &__image {
            height: responsive(176, 640);
            border-radius: responsive(20, 30);
        }

        &__zoom-btn {
            top: calc(100% - responsive(40, 44));
            right: calc(100% - responsive(40, 44));
        }
    }
}