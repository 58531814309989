@import 'src/styles/responsive';

.location {
    background-color: #fff;

    &__container {
        position: relative;

        & .section {
            padding-top: responsive(60, 100, 'mobile-adaptive');
            padding-bottom: responsive(60, 100, 'mobile-adaptive');
        }
    }

    &__address {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: responsive(20, 60, 'mobile-adaptive');
    }

    & .address {
        max-width: 519px;

        display: flex;
        flex-direction: column;
        gap: 6px;
    
        &__title {
            text-align: center;
            font-family: 'Futuris';
            font-size: 20px;
            font-size: responsive(16, 20, 'mobile-adaptive');
            font-weight: 700;
            line-height: repsonsive(20, 24, 'mobile-adaptive');
    
            color: #104431;
        }
    
        &__value {
            text-align: center;
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 500;
            line-height: responsive(20, 24, 'mobile-adaptive');
    
            color: #1D694E;
        }
    }

    &__btn {
        display: none;
    }

    &__map {
        margin-top: 60px;
        height: 360px;
        width: 100%;

        border: 1px solid #568977;
        border-radius: responsive(20, 40, 'mobile-adaptive');
        overflow: hidden;
    }

    & .swiper {
        margin-top: 30px;
    }

    @media screen and (min-width: 769px) {
        &__container {
            & .section {
                padding-top: responsive(60, 100);
                padding-bottom: responsive(60, 100);
            }
        }

        &__address {
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: responsive(20, 60);
        }

        & .address {
            &__title {
                font-size: responsive(16, 20);
                line-height: repsonsive(20, 24);
            }

            &__value {
                line-height: responsive(20, 24);
            }
        }

        &__map {
            border-radius: responsive(20, 40);
        } 
    }

    @media screen and (min-width: 1101px) {
        &__btn {
            width: fit-content;
            justify-self: center;
            display: block;
            margin-top: 40px;
        }
    }
}


