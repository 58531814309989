@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInWithDisplayFlex {
    0% { opacity: 0; display: none; }
    1% { opacity: 0; display: flex; }
    100% { opacity: 1; display: flex; }
}

@keyframes fadeOutWithDisplayFlex {
    0% { opacity: 1; display: flex; }
    99% { opacity: 0; display: flex; }
    100% { opacity: 0; display: none; }
}

@keyframes slideDown {
    from { max-height: 0px; opacity: 0; }
    to { max-height: 1000px; opacity: 1; }
}