.how-it-was {
    & .swiper,
    & .swiper-container {
        overflow: visible;
    }

    @media screen and (min-width: 1440px) {
        & .swiper,
        & .swiper-container {
            overflow: hidden;
        }
    }
}