@import 'src/styles/responsive';

.book-ticket {
    display: grid;
    row-gap: responsive(20, 40, 'mobile-adaptive');

    &__inputs {
        display: grid;
        row-gap: responsive(14, 20, 'mobile-adaptive');
    }

    &__checkboxes {
        display: grid;
        row-gap: 10px;
    }

    &__error {
        color: #D96060;
    }

    @media screen and (min-width: 450px) {
        &__btn {
            width: fit-content;
        }
    }

    @media screen and (min-width: 769px) {
        row-gap: responsive(20, 40);

        &__inputs {
            row-gap: responsive(14, 20);
        }
    }
}